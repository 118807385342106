body{
  background-image:url('img/home.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  font-family: sans-serif;
  @media (max-width: 576px) {
    background-size: contain;
    background-color: #a58d73;
  }
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  color: white;
  text-align: justify;
  line-height: 20px;
}

h2 {
  padding: 10px 0 20px 0;
  margin: 0;
  color: white;
  font-size: 35px;
  text-align: center;

  @media (max-width: 576px) {
    padding: 0px 0 5px 0;
  }

  
}

h3 {
  padding: 0;
  margin: 0;
  font-size: 23px;
  text-align: center;
}

h4 {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: white;
}

.ggh {
  /* margin: 500px 15% 2% 15%; */
  margin: 500px 15% 2% 15%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* background: black; */

  @media (max-width: 576px) {
    margin: 0px 5% 2% 5%;
  }
}

.prova{
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 100px;
  @media (max-width: 576px) {
    gap: 60px;
  }
}
